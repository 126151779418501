import React, { useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import { formatDistanceToNow, formatISO } from "date-fns"
import { zhCN } from "date-fns/locale"
import { Disqus } from "gatsby-plugin-disqus"
// import Img from "gatsby-image"

// import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"

import { SEO as Seo } from "../components/seo"
import { mediaQueries } from "../config/mediaQueries"
import { PostTags } from "../components/PostTags"
import { Layout } from "../components/layout"
import "../css/blog.css"

const isProd = process.env.NODE_ENV === "production"

const Container = styled.div`
  padding: 1rem;
  margin: 0 auto;
  ${mediaQueries("sm")`
    width: 670px;
    padding: 2rem 0;
  `}
  .blog-post__content > p,
  .blog-post__content > ul {
    line-height: 1.8;
  }
  & + & {
    padding-top: 0;
  }
`

const BannerContainer = styled.div``

export default function BlogPost({ data, pageContext }) {
  const { markdownRemark: post } = data
  const { siteUrl } = data.site.siteMetadata
  const canonicalBaseUrl = siteUrl
  const { frontmatter } = post

  // 添加代码高亮支持
  // https:// medium.com/better-programming/stylish-cards-and-syntax-highlighting-with-gatsby-e2806b9cb987
  useEffect(() => {
    const loader = async () => {
      try {
        const deckdeckgoLoader = require("@deckdeckgo/highlight-code/dist/loader")
        await deckdeckgoLoader.defineCustomElements(window)
      } catch (err) {
        console.error(err)
      }
    }
    loader()
  }, [])

  const dt = new Date(post.frontmatter.date)
  const { slug } = pageContext
  const disqusConfig = isProd
    ? {
      url: `${canonicalBaseUrl}/post${slug}`,
    }
    : {}

  return (
    <Layout>
      <Seo title={post.frontmatter.title} description={post.excerpt || "-"} />
      <Helmet>
        <link rel="canonical" href={`${canonicalBaseUrl}/post${slug}`} />
      </Helmet>
      <div>
        <Container>
          <div className="blog-post">
            <h1>{post.frontmatter.title}</h1>

            <div className="blog-post__meta">
              <ul className="metas">
                <li>
                  {dt ? (
                    <>
                      <span title={formatISO(dt)}>
                        {formatDistanceToNow(dt, { locale: zhCN })}
                      </span>
                    </>
                  ) : (
                    <span>-</span>
                  )}
                </li>
                <li>by {frontmatter.author || "admin"}</li>
              </ul>
            </div>
            <PostTags tags={post.frontmatter.tags} />
          </div>
        </Container>

        {post.frontmatter.banner ? (
          <>
            <BannerContainer>
              <div>
                <GatsbyImage className="post__image" image={post.frontmatter.banner.childImageSharp.gatsbyImageData}
                  style={{}}
                />
              </div>
            </BannerContainer>
          </>
        ) : null}

        <Container>
          <div className="blog-post">
            <div
              className="blog-post__content"
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
          </div>
          <div className="blog-comments">
            <Disqus config={disqusConfig} />
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query BlogQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt(pruneLength: 140)
      frontmatter {
        title
        author
        date
        banner {
          childImageSharp {
            # fluid(maxWidth: 1400, toFormat: WEBP) {
            #   ...GatsbyImageSharpFluid
            # }
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        tags
      }
    }
    site {
      siteMetadata {
        title
        description
        siteUrl
        lang
      }
    }
  }
`
